import { useTranslation } from 'next-i18next';

import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { useIsMutating } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import BinIcon from '@assets/icons/BinIcon';
import TimesIcon from '@assets/icons/TimesIcon';
import Button from '@components/elements/Button';
import ConditionalWrapper from '@components/elements/ConditionalWrapper';
import Modal from '@components/elements/Modal/Modal';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import {
  selectMultinational,
  useAppConfigSelector,
  useAppMode,
} from '@hooks/useAppConfigSelectors';
import useOverflowBody from '@hooks/useOverflowBody';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import BasketActionStatic from '../BasketStatic/BasketAction';
import BasketStaticContent from '../BasketStatic/BasketContent';
import useBasketPrices from '../BasketStatic/useBasketPrices';

import BasketAction from './BasketAction';
import BasketNormalContent from './BasketContent';

const MobileBasket = ({
  isFixedButton = false,
  dietElements,
  handleClickClearStaticBasket,
  isBasketStatic,
  isEmptyBasket,
  isMobileBasketOpen,
  price,
  setIsMobileBasketOpen,
  currentDiet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const basketDishIsMutating = useIsMutating({
    mutationKey: ['basketAddDish'],
  });
  const basketAddonIsMutating = useIsMutating({
    mutationKey: ['basketAddAddon'],
  });
  const isMutatingBasket = [basketDishIsMutating, basketAddonIsMutating].some(
    Boolean
  );

  const basketPrices = useBasketPrices({ dietElements });

  const { isDietFull } = useAppMode();
  const { dietLength, deliveryDates = [] } = currentDiet || {};

  const handleOpenBasket = () => {
    dispatch(setIsMobileBasketOpen(true));
  };

  const handleCloseBasket = () => {
    dispatch(setIsMobileBasketOpen(false));
  };

  useOverflowBody(isMobileBasketOpen);

  const basketPrice = getIntegerIfIntegerOrFixed(
    isBasketStatic
      ? basketPrices?.price?.afterDiscount ?? 0
      : price?.afterDiscount ?? 0
  );

  const priceBasketBeforeDiscount = getIntegerIfIntegerOrFixed(
    isBasketStatic
      ? basketPrices?.price?.beforeDiscount ?? 0
      : price?.beforeDiscount ?? 0
  );

  const isFullCalendarValid = !isDietFull
    ? true
    : deliveryDates?.length === dietLength;

  if (isFixedButton) {
    return (
      <>
        <Modal isOpen={isMobileBasketOpen} onClose={handleCloseBasket}>
          <Modal.Content>
            <div tw="flex mb-4 pb-1.5 border-b border-gray-1 items-center justify-between font-semibold text-primary">
              <div tw="flex -mx-2">
                <h2 className="h3" tw="mb-0 px-2">
                  {t('$*components.basket.title')}
                </h2>
                {basketPrice > 0 && (
                  <div tw="text-lg px-2">
                    {basketPrice} {currencySymbol}
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  tw="border-transparent text-gray-2 transform duration-300 flex items-center justify-center rounded-lg cursor-pointer outline-none hover:text-gray-3 focus-visible:(outline-none ring-gray-1)"
                  onClick={handleCloseBasket}
                >
                  <TimesIcon tw="w-4 fill-current" />
                </button>
              </div>
            </div>

            <div
              tw="overflow-auto"
              css={css`
                height: calc(100vh - 160px);
              `}
            >
              {isBasketStatic ? (
                <BasketStaticContent
                  dietsPrices={basketPrices.rowsPrices}
                  isEmptyBasket={isEmpty(dietElements)}
                />
              ) : (
                <BasketNormalContent />
              )}
            </div>
          </Modal.Content>
        </Modal>
        {createPortal(
          <div tw="fixed bottom-0 left-0 right-0 z-50">
            <div className="container" tw="relative mt-auto z-50">
              <div tw="py-4">
                {isMobileBasketOpen ? (
                  <BasketAction />
                ) : (
                  <Button
                    fullWidth
                    onClick={handleOpenBasket}
                    isLoading={isMutatingBasket}
                  >
                    {t('$*components.mobileBasket.closedBasket.button', {
                      replace: {
                        price: basketPrice,
                        currencySymbol,
                      },
                    })}
                  </Button>
                )}
              </div>
            </div>
          </div>,
          document.querySelector('#modalPortal')
        )}
      </>
    );
  }

  return (
    <>
      <ConditionalWrapper
        condition={!isFullCalendarValid}
        wrapper={children => (
          <Tooltip content={t('$*components.basket.dietLengthIsInvalid')}>
            <div>{children}</div>
          </Tooltip>
        )}
      >
        <Button
          fullWidth
          onClick={handleOpenBasket}
          isLoading={isMutatingBasket}
          disabled={!isFullCalendarValid}
        >
          {t('$*components.mobileBasket.button', {
            price: basketPrice,
            currencySymbol,
          })}
        </Button>
      </ConditionalWrapper>

      <Modal isOpen={isMobileBasketOpen} onClose={handleCloseBasket}>
        <Modal.Content>
          <div tw="pb-4 flex-auto">
            <div tw="flex mb-4 pb-1.5 border-b border-gray-1 items-center justify-between font-semibold text-primary">
              <div tw="flex items-center -mx-2">
                <h2 className="h3" tw="mb-0 px-2">
                  {t('$*components.basket.title')}
                </h2>
                {basketPrice < priceBasketBeforeDiscount && (
                  <span tw="text-red-1 line-through">
                    {priceBasketBeforeDiscount} {currencySymbol}
                  </span>
                )}
                {basketPrice > 0 && (
                  <div tw="text-lg px-2">
                    {basketPrice} {currencySymbol}
                  </div>
                )}
                {!isEmptyBasket && (
                  <Tooltip content={t('$*common.clearBasket')}>
                    <div
                      tw="ml-3 text-red-1"
                      onClick={() => {
                        handleClickClearStaticBasket();
                      }}
                    >
                      <BinIcon tw="w-4 cursor-pointer" />
                    </div>
                  </Tooltip>
                )}
              </div>
              <div>
                <button
                  type="button"
                  tw="border-transparent text-gray-2 transform duration-300 flex items-center justify-center rounded-lg cursor-pointer outline-none hover:text-gray-3 focus-visible:(outline-none ring-gray-1)"
                  onClick={handleCloseBasket}
                >
                  <TimesIcon tw="w-4 fill-current" />
                </button>
              </div>
            </div>

            <div tw="overflow-auto">
              {isBasketStatic ? (
                <BasketStaticContent
                  dietsPrices={basketPrices.rowsPrices}
                  isEmptyBasket={isEmpty(dietElements)}
                />
              ) : (
                <BasketNormalContent />
              )}
            </div>
          </div>

          <div tw="py-2">
            {isBasketStatic ? (
              <BasketActionStatic
                buttonNextText={t('$*components.mobileBasket.button', {
                  replace: {
                    price: basketPrice,
                    currencySymbol,
                  },
                })}
                isMobileBasket
              />
            ) : (
              <BasketAction
                buttonNextText={t('$*components.mobileBasket.button', {
                  replace: {
                    price: basketPrice,
                    currencySymbol,
                  },
                })}
                isMobileBasket
              />
            )}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default withMemoBasketMethods(MobileBasket, [
  'handleClickClearStaticBasket',
  'isBasketStatic',
  'isEmptyBasket',
  'setIsMobileBasketOpen',
  'currentDiet',
  { as: 'dietElements', path: 'basketStore.items.dietElements' },
  { as: 'isMobileBasketOpen', path: 'basketStore.isMobileBasketOpen' },
  { as: 'price', path: 'basketQuery.data.price', defaultValue: {} },
]);
