import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useIsMutating } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import AddMoreDietButton from '@components/modules/AddMoreDietButton/AddMoreDietButton';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import ROUTE_URLS from '@constants/routeUrls';
import {
  nextStep,
  selectIsNextDisabled,
  selectTabs,
} from '@features/orderTabs/orderTabsSlice';
import useTriggerGtmEvents from '@hooks/basket/useTriggerGtmEvents';
import { useAppMode } from '@hooks/useAppConfigSelectors';
import { isCMSPage, isDietPage } from '@utils/helpers';

import useAddMoreCondition from '../hooks/useAddMoreCondition';

import PaymentSummaryModal from './PaymentSummaryModal';

const BasketAction = ({
  buttonNextText,
  basketStore,
  closePaymentSummaryModal,
  currentDiet,
  isBasketEditOrder,
  isMutatingBasket,
  isPaymentSummaryModalOpen,
  openPaymentSummaryModal,
  setIsMobileBasketOpen,
  isMobileBasket,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const { isShop, isDietFull } = useAppMode();

  const { currentStepIndex, steps, stepsCount } = useSelector(selectTabs);

  const triggerGtmEvents = useTriggerGtmEvents();

  const { dietLength, paymentMode, deliveryDates = [] } = currentDiet;
  const isSubscription =
    paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

  const isFullCalendarValid = !isDietFull
    ? true
    : deliveryDates?.length === dietLength;

  const isMutatingBasketNewOrder =
    useIsMutating({
      mutationKey: ['basketNewOrder'],
    }) === 1;

  const isNextDisabled = useSelector(selectIsNextDisabled);

  const basketIsValidToPayment = basketStore.isValid && isFullCalendarValid;
  const isValidNextStep =
    !isNextDisabled &&
    basketIsValidToPayment &&
    !isMutatingBasket &&
    !isMutatingBasketNewOrder;
  const isLastStep = currentStepIndex === stepsCount - 1;

  const shouldShowAddMoreDietButton = useAddMoreCondition({ isMobileBasket });

  const handleCloseBasket = () => dispatch(setIsMobileBasketOpen(false));

  const handleNextStep = () => {
    dispatch(nextStep());

    triggerGtmEvents();

    window.scrollTo({ top: 0 });

    handleCloseBasket();
  };

  if (isCMSPage(router.pathname)) {
    return (
      <Button
        onClick={async () => {
          if (isDietPage(router.pathname)) {
            await router.push({
              pathname: ROUTE_URLS.NEW_ORDER_FORM,
              query: { step: steps?.length > 1 ? steps[1]?.slug : '' },
            });
          } else {
            await router.push(ROUTE_URLS.NEW_ORDER_FORM);
          }

          handleCloseBasket();
        }}
        fullWidth
        isLoading={isMutatingBasket}
        data-cy="basket-action__button--next"
        id="order-form-next-button"
        disabled={isEmpty(currentDiet)}
      >
        {buttonNextText ?? t('$*components.basket.next')}
      </Button>
    );
  }

  if (isBasketEditOrder) {
    return (
      <>
        <Button
          fullWidth
          onClick={openPaymentSummaryModal}
          isLoading={isMutatingBasket}
          data-cy="basket-action__button--pay"
        >
          {t('$*components.basket.pay')}
        </Button>
        <PaymentSummaryModal
          isOpen={isPaymentSummaryModalOpen}
          onClose={closePaymentSummaryModal}
        />
      </>
    );
  }

  if (isShop && isSubscription && !isLastStep) {
    return (
      <Tooltip
        content={t('$*components.basket.removeDishesShopSubscription')}
        disabled={basketIsValidToPayment}
      >
        <Button
          fullWidth
          onClick={handleNextStep}
          isLoading={isMutatingBasket}
          disabled={!isValidNextStep}
          data-cy="basket-action__button--next"
          id="order-form-next-button"
        >
          {buttonNextText ?? t('$*components.basket.next')}
        </Button>
      </Tooltip>
    );
  }

  if (isLastStep) {
    return null;
  }

  if (!isFullCalendarValid) {
    return (
      <Tooltip content={t('$*components.basket.dietLengthIsInvalid')}>
        <Button
          fullWidth
          onClick={handleNextStep}
          isLoading={isMutatingBasket}
          disabled={!isValidNextStep}
          data-cy="basket-action__button--next"
          id="order-form-next-button"
        >
          {buttonNextText ?? t('$*components.basket.next')}
        </Button>
      </Tooltip>
    );
  }

  if (shouldShowAddMoreDietButton) {
    return (
      <>
        <Button
          fullWidth
          onClick={handleNextStep}
          isLoading={isMutatingBasket}
          disabled={!isValidNextStep}
          data-cy="basket-action__button--next"
          id="order-form-next-button"
        >
          {buttonNextText ?? t('$*components.basket.next')}
        </Button>
        <AddMoreDietButton wrapperTwStyle={tw`mt-2 sm:mt-4`} />
      </>
    );
  }

  return (
    <Button
      fullWidth
      onClick={handleNextStep}
      isLoading={isMutatingBasket}
      disabled={!isValidNextStep}
      data-cy="basket-action__button--next"
      id="order-form-next-button"
    >
      {buttonNextText ?? t('$*components.basket.next')}
    </Button>
  );
};

export default withMemoBasketMethods(BasketAction, [
  'basketStore',
  'closePaymentSummaryModal',
  'currentDiet',
  'isBasketEditOrder',
  'isMutatingBasket',
  'isPaymentSummaryModalOpen',
  'openPaymentSummaryModal',
  'setIsMobileBasketOpen',
]);
