import { useSelector } from 'react-redux';

import TAB_SLUGS from '@constants/tabSlugs';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';

type UseAddMoreConditionArgs = {
  isMobileBasket: boolean;
};

const useAddMoreCondition = ({ isMobileBasket }: UseAddMoreConditionArgs) => {
  const { currentStepIndex, steps } = useSelector(selectTabs) as {
    currentStepIndex: number;
    steps: { [key: string]: unknown }[];
  };
  const deliveryDataStepIndex =
    steps.findIndex(step => step.slug === TAB_SLUGS.DELIVERY_DATA) ?? -1;
  const authorizationStepIndex =
    steps.findIndex(step => step.slug === TAB_SLUGS.AUTHORIZATION) ?? -1;

  return (
    [
      deliveryDataStepIndex,
      deliveryDataStepIndex - 1,
      authorizationStepIndex - 1,
    ].includes(currentStepIndex) ||
    ([deliveryDataStepIndex - 1, authorizationStepIndex - 1].includes(0) &&
      isMobileBasket)
  );
};

export default useAddMoreCondition;
