import { useTranslation } from 'next-i18next';

import { type Dispatch, type SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInitialDiet } from '@store/basket/basketInitialData.slice';
import { setIsMobileBasketOpen } from '@store/basket/basketNewOrder.slice';
import tw, { type TwStyle } from 'twin.macro';

import Button from '@components/elements/Button';
import { changeStepAndResetValid } from '@features/orderTabs/orderTabsSlice';
import useAppMode from '@hooks/useAppMode';
import useBasket from '@hooks/useBasket';
import useBasketMethods from '@hooks/useBasketMethods';
import useMobileDetect from '@hooks/useMobileDetect';
import { pickRenameKeysInitialDiet } from '@services/Basket.service';
import { useNewOrderCreatePageContext } from '@views/NewOrder/NewOrderCreatePage/NewOrderCreatePage.context';

type AddMoreDietButtonProps = { wrapperTwStyle?: TwStyle };

const AddMoreDietButton = ({
  wrapperTwStyle = tw``,
}: AddMoreDietButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const { data: basket = {} } = useBasket({
    basketKey: 'basketNewOrder',
  });

  const initialDiet = useSelector(selectInitialDiet);
  const { isDietFull } = useAppMode();
  const {
    handleAddBasketDiet,
    isBasketStatic,
    createBasketFromStaticBasket,
    isMutatingBasket,
  } = useBasketMethods() as unknown as {
    handleAddBasketDiet: (basketItem: {
      [key: string]: unknown;
    }) => Promise<void>;
    createBasketFromStaticBasket: (overrideDiet?: {
      [key: string]: unknown;
    }) => Promise<void>;
    isBasketStatic: boolean;
    isMutatingBasket: boolean;
  };

  const { setIsRedirectedAfterAddMoreDiet } =
    useNewOrderCreatePageContext() as unknown as {
      setIsRedirectedAfterAddMoreDiet: Dispatch<SetStateAction<boolean>>;
    };

  const handleAddMore = async () => {
    if (isBasketStatic) {
      await createBasketFromStaticBasket();
    }

    await handleAddBasketDiet(pickRenameKeysInitialDiet(initialDiet));

    setIsRedirectedAfterAddMoreDiet(true);

    dispatch(changeStepAndResetValid(0));

    window.scrollTo({ top: 0 });

    if (isMobile) {
      dispatch(setIsMobileBasketOpen(false));
    }
  };

  if (!isDietFull || basket.testMode) {
    return null;
  }

  return (
    <div css={wrapperTwStyle}>
      <Button
        className="basket-action__button--add-more"
        fullWidth={true}
        onClick={handleAddMore}
        variant="outlined"
        data-cy="basket-action__button--add-more"
        styles={{ button: tw`bg-white` }}
        isLoading={isMutatingBasket}
      >
        {t('$*components.basket.addMore')}
      </Button>
    </div>
  );
};

export default AddMoreDietButton;
